.sideBar {
  height: 100vh;
  position: fixed;
  background-color: #221f1f;
  width: 285px;
  color: rgba(165, 165, 165, 1);
  overflow-y: scroll;
  overflow-x: hidden;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
}

.userNameDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: -4px;
  margin-right: 7px
}

.userNamesubDiv {
  display: flex;
  word-break: break-word;
}

.btnChange {
  width: 100px;
  word-break: break-word;
  white-space: initial;
  font-weight: lighter;
  font-size: 12px;
  color: #FFFFFF;
  background-color: #32c5d2;
  border-color: #32c5d2;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  border: 1px solid transparent;
  padding: 10px 20px;
  margin-bottom: 0;
}

.SidebarCollapsed {
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  position: fixed;
  background-color: #221f1f;
  width: 70px;
  color: rgba(165, 165, 165, 1);

  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
}

.sidebarHead {
  justify-content: space-between;

  padding: 25px !important;
  display: flex;
}

.sidebarTopIcon {
  width: 180px;
  padding: 0.3125rem 0;
}

.sidebarHeadItem {
  display: inline-block;
}

.sidebarHeadItem1 {
  margin: 9px;
  color: white;
  /* font-weight: 100; */
  font-size: 32px !important;
  cursor: pointer;
}

.sidebarHeadItem1Collapsed {
  margin: 12px;

  font-size: 30px !important;
  cursor: pointer;
}

.accordionDiv {
  background-color: #221f1f !important;
  padding: 20px 0px !important;
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
  cursor: pointer;
}

.accordionDivNone {
  display: none;
}

.mainaccordionDiv {
  background-color: #221f1f !important;
  padding: 20px 0px !important;
  /* padding-left: 15px; */
  text-align: left;
  cursor: pointer;
}

.mainhead {
  display: flex;
  flex-direction: column;
  background-color: #221f1f;
}

.navLinkText {
  color: #939598;
  font-size: 17px;
}

.iconNavText {
  color: #939598;
  font-size: 17px;
  padding-left: 12px;
}

.panela-header {
  color: #868e96 !important;
}

.subMenu {
  padding-left: 15px;
  font-size: 14px;
  color: #fff;
  font-weight: lighter;
}

.subIcon {
  color: #fff;
}

.itemIcon {
  color: #fff;
  padding-left: 12px;
}

.accodiondetails {
  padding: 8px 16px 16px !important;

  text-decoration: none;
}
.subaccodiondetails {
  padding: 0px !important;

  text-decoration: none;
}
.mainnavLinkText {
  text-align: left;
  padding-left: 15px;
  font-size: 15px;
  color: #fff;
  font-weight: 300;
}

.mainNavSubtext {

  align-items: center;
  padding-left: 10px;
  padding-bottom: 20px;
  font-size: 25px;
  color: #0094cd;
  font-weight: 600;

}

.mainprofileText {
  font-size: 19px;
  color: #fff;
  font-weight: 300;
  margin-top: 10px;
  padding-left: 15px;
  text-align: left;
  margin-bottom: 10px;
}

.submainprofileText {
  font-size: 15px;
  color: #fff;
  text-align: left;
  padding-left: 15px;
  font-weight: 300;
}

.accordionDivRow {
  background-color: #221f1f !important;
  padding: 20px 0px !important;
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
  cursor: pointer;
  display: flex;
  flex-direction: row;
}

.imgstyle {
  width: 22px;
}

.dataRow {
  background-color: #110f10 !important;
  border-left: 4px solid #a4cf37 !important;
  padding: 11px 11px 11px !important;
  display: flex !important;
  text-decoration: none !important;
}

.dataRow #active {
  text-decoration: none;
}

.topNavBar {
  display: none;
}

.imgFlex {
  display: none;
}

.flexRow {
  background-color: #2f353b;
  height: 110px;
  display: flex;
  flex-direction: column;
}

.logoFlex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 15px;
}

.logoText {
  width: 175px;
  display: block;
}

.subspanTitle {
  font-size: 15px;
  color: #fff;
  margin-bottom: 20px;
  margin-left: 10px;
  text-align: left;
}

.hamenuIcon {
  font-size: 46px;
  color: white;
}

.sidebarMobile {
  height: 570px;
  position: fixed;
  z-index: 1;
  width: 100%;
  color: rgba(165, 165, 165, 1);
  overflow-y: hidden;
  overflow-x: scroll;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
}

@media (max-width: 1024px) {
  .logoText {
    width: 48px;
    display: block;
  }
}