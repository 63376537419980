.tabChange {
	text-decoration: none !important;
	color: #337ab7;
}
.accountContent {
	display: flex;
	flex-direction: row;

	overflow-x: hidden;
	/* display: flex;
  flex-direction: row;
  background-color: #e1e1e1;
  height: 100vh; */
}
.accountView {
	background-color: #fff;
	/* margin-left: 34px; */
	width: 100%;
	/* height: 590px; */

	display: flex;
	flex-direction: column;
	padding: 25px;
}
.accounthead {
	display: flex;
	flex-direction: row;
	padding-bottom: 15px;
}
.accountheadIcon {
	color: black;
	height: 20px;
}
.accountToptext {
	font-size: 20px;
	color: #29b4b6;
	padding-left: 15px;
}
.accountNavtext {
	font-size: 20px;
	color: #29b4b6;
}
.userPageToptext {
	font-size: 20px;
	color: #29b4b6;
}
.accouttablestyle {
	border: 1px solid #e7ecf1;
	width: 100%;
	margin-bottom: 20px;
	margin-left: 2%;
	border-collapse: collapse;
	border-spacing: 0;
}
.tableHead {
	font-size: 12px !important;
	font-weight: 600 !important;
}
.companyForm {
	display: flex;
	flex-direction: column;
}
.imgFileRow {
	display: flex;
	flex-direction: column;

	margin-left: 25%;
}
.imgiconRow {
	display: flex;
	flex-direction: column;

	margin-left: 30%;
}
.InputflexRow {
	display: flex;
	flex-direction: row;
	margin-bottom: 6px;
}
.errcmpnyFiled {
	height: 30px;
	width: 100%;
	color: #555;
	outline: none;
	margin-bottom: 10px;
	background-color: #b956563b;
	border-radius: 3px !important;
	border: 1px solid #ff0000;
}

.fileRow {
	display: block;
	border: 2px solid #c8c9ce;
	color: #93c47d;
	cursor: pointer;
	height: 30px;
	line-height: 40px;
	text-align: left;
	background: #ffffff;
	/* overflow: hidden; */
	position: relative;
	border-radius: 5px !important;
}

.filesubText {
	font-size: 10px;
	color: #333333;
	padding-bottom: 8px;
	display: inline-block;
}
.fileIcon {
	width: 70px;
	height: 70px;
	background-color: #ddd;
	border: 2px;
	border-radius: 7px !important;
	float: left;
}

.imgclasss {
	width: 70px;
	height: 70px;
}
input[type="file"] {
	height: 31px;
	display: inline-block;
	padding: 0;
	border: 1px solid #cccccc;
	width: 100%;
	border-radius: 4px;
	overflow: hidden;
	font-size: 13px;
	cursor: pointer;
	color: #afa0bb;
}
input[type="file"]::file-selector-button {
	padding: 0 3px !important;
	display: inline-block !important;
	background-color: white;
	height: 31px;
	font-size: 13px !important;
	font-weight: 700 !important;
	border: 2px solid #6c99e1 !important;
	border-radius: 3px !important;
	cursor: pointer;
	/* margin: -2px 0 0px -2px !important; */
}
.inputField {
	height: 33px;
	width: 100%;
	outline: none;
	font-size: 13px;
	padding: 0px 12px;
	background-color: #f4f4f4;
	border-radius: 3px !important;
	border: 1px solid #c2cad8;
}
.inputFielderr {
	height: 33px;
	width: 100%;
	outline: none;
	padding: 0px 12px;
	background-color: #edd;
  	border: 1px solid #c00;
	border-radius: 3px !important;
}
.input_Field {
  height: 34px;
  width: 100%;
  outline: none;
  padding: 0px 12px;
  background-color: #eef1f5;
  border-radius: 6px !important;
  border: 1px solid #c2cad8;
}
.labelDirect {
	margin-bottom: 5px;
	font-weight: normal;
	font-size: 12px;
	text-align: left;
	color: #333333;
	font-family: "Open Sans", sans-serif !important;
}
.label_Direct {
  margin-bottom: 5px;
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  color: #333333;
  font-family: "Open Sans", sans-serif !important;
}
.gridHead {
	font-weight: 700;
	font-size: 14px;
	text-align: left;
	color: #333333;
	font-family: "Open Sans", sans-serif !important;
}
.datafeeBox {
	margin-bottom: 15px;
	border-radius: 5px !important;
	padding: 15px;
	border: 1px solid #ccc !important;
	display: flex;
	margin-left: 13px;
}
.datafeeSubBox {
	background-color: #f4f4f4;
	border-radius: 3px !important;
	border: 1px solid #c2cad8;
	padding: 10px;
	overflow-y: scroll;
	height: 155px;
}
.boxRow {
	display: flex;
	flex-direction: row;
	/* justify-content: space-between; */
}
.closeIcon {
	color: #fff;
	background-color: #ed6b75;
	border-color: #ea5460;
	display: inline-block;
	padding: 6px 12px;
	font-size: 14px;
	cursor: pointer;
}
.inputBoxRow {
	height: 27px;
	background-color: #f4f4f4;
	border-radius: 3px !important;
	margin-bottom: 8px;
}

.inputBox_Row {
	height: 30px;
	border-radius: 5px !important;
	margin-bottom: 8px;
  padding-left: 10px;
	color: #333;
	margin-left: 25px;
	border: 1px solid #c2cad8;
}

.inputBox_Rowerr {
	height: 30px;
	border-radius: 5px !important;
	margin-bottom: 8px;
    padding-left: 10px;
	color: #333;
	margin-left: 25px;
	background-color: #edd;
  	border: 1px solid #c00;
}

.BoxsubFieldInput {
	height: 25px;
	width: 50%;
	margin-left: 5px;
	margin-right: 5px;
	outline: none;
	background-color: #f4f4f4;
	border-radius: 3px !important;
	border: 1px solid #c2cad8;
}

.BoxsubFieldInputerr {
	height: 25px;
	width: 50%;
	margin-left: 5px;
	margin-right: 5px;
	outline: none;
	background-color: #edd;
  	border: 1px solid #c00;
	border-radius: 3px !important;
}
.errEdit{
	background-color: #edd !important;
	border: 1px solid #c00 !important;
  border-radius: 3px !important;
}
.directFieldInput {
	height: 25px;
	width: 100%;
	padding-left: 7px;
	outline: none;
	background-color: #f4f4f4;
	border-radius: 3px !important;
	border: 1px solid #c2cad8;
	font-size: 13px;
}
.directFieldActive {
	height: 25px;
	width: 100%;
	padding-left: 7px;
	outline: none;
	background-color: white;
	border-radius: 3px !important;
	border: 1px solid #c2cad8;
}
.directFieldActiveerr {
	height: 25px;
	width: 100%;
	padding-left: 7px;
	outline: none;
	background-color: #edd;
    border: 1px solid #c00;
    border-radius: 1px !important;
}
.direct_FieldInput {
	height: 34px;
	width: 100%;
	padding-left: 7px;
	outline: none;
	background-color: #f4f4f4;
	border-radius: 3px !important;
	border: 1px solid #c2cad8;
}
.directFieldSelect {
	height: 34px;
	width: 100%;
	padding-left: 7px;
	outline: none;
	background-color: #ffffff;
	border-radius: 3px !important;
	border: 1px solid #c2cad8;
}
.fieldStyle {
	font-size: 14px;
    font-weight: 300;
    color: #555;
}
.directField_Input {
	height: 30px;
	width: 97%;
	padding-left: 7px;
	outline: none;
	background-color: #ffffff;
	border-radius: 3px !important;
	border: 1px solid #c2cad8;
}

.directField_Inputerr {
	height: 30px;
	width: 97%;
	padding-left: 7px;
	outline: none;
	background-color: #edd;
  	border: 1px solid #c00;
	border-radius: 3px !important;
	
}
.rowClass {
	display: flex;
	flex-direction: row;
}

.loadimg{
	width: 110px;
    height: 110px;
    background-color: #ddd;
    border: 2px;
    border-radius: 7px !important;
    float: left;
}
.loadimg img{
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 7px !important;
}

.fileIcon img{
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 7px !important;
}

.splashimg img{
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 7px !important;
}
.splashimg{
    width: 110px;
    height: 150px;
    border: 2px;
    border-radius: 7px !important;
    float: left;
}
.BoxFieldInput {
	height: 25px;
	width: 100%;
	margin-left: 5px;
	margin-right: 5px;
	outline: none;
	background-color: #f4f4f4;
	border-radius: 3px !important;
	border: 1px solid #c2cad8;
	/* margin-left: 15px; */
}
.dailyLimit{
	margin-top: 2%;
}
.entyPointurlfield {
	height: 33px;
    width: 90%;
    margin-right: 2%;
   
    outline: none;
    padding: 0px 12px;
    background-color: #f4f4f4;
    border-radius: 3px !important;
    border: 1px solid #c2cad8;
}
.alignRight{
	text-align: right !important;
}
.editentyPointurlfield {
	height: 33px;
	outline: none;
	width: 90%;
	flex: 1;
	padding: 0px 12px;
	background-color: #ffffff;
	border-radius: 3px !important;
	border: 1px solid #c2cad8;
}
.colorPickerDiv{
	width: auto;
    display: inline-block;
    position: relative;
}
.colorBox{
	
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    vertical-align: middle;
    background-position: -80px 0;
    border: solid 1px #ccc  !important;
  
    padding: 0 !important;
    width: 25px !important;
    height: 24px !important;
    margin: 0 !important;
    display: inline-block !important;
    cursor: pointer !important;
}
.textareaField{
	color:red !important;
	padding: 6px 12px !important;
	font-size: 14px !important;
    display: block !important;

}
.launchurlInputfield {
   
    outline: none;
    cursor: pointer;
    text-align: center;
    width: 90%;
    margin-left: -1px;
    margin-top: 2%;
    
    background-color: #ffffff;
    border-radius: 6px !important;
    border: 1px solid #93c47d;
    color: #93c47d;
    font-weight: bold;
    padding: 3px 14px;
}
.colorInput{
	
	display: inline-block;
    height: 24px;
    padding-left: 29px;
    width: 100px;

}
.entyPointfield {
	height: 34px;
    width: 80%;
    margin-right: 2%;
    outline: none;
	font-size: 14px;
    padding: 0px 12px;
    background-color: #f4f4f4;
    border: 1px solid #c2cad8;
}
.flexrow{
	display:flex;
	flex-direction: row;

}
.backGrndCOLOR{
  background-color: #ffffff !important;
}
.editentyPointfield {
	height: 34px;
	outline: none;
	width: 80%;
	margin-right: 2%;
	flex: 1;
	padding: 0px 12px;
	background-color: #ffffff;
	border: 1px solid #c2cad8;
}
.entryinputField {
	height: 34px;
	/* width: 15%; */
	font-size: 14px;
	margin-right: 5%;
	margin-left: 4%;
	margin-bottom: 2%;
	outline: none;
	padding: 1px 9px 1px 8px;
	background-color: #f4f4f4;
	
	border: 1px solid #c2cad8;
  }
.borderDiv{
	border-radius: 5px !important;
    padding: 15px;
    border: 1px solid #ccc!important;
	margin-bottom: 15px;
}
  .editentryinputField {
	height: 34px;
   /* width: 15%; */
	
	margin-right: 5%;
	margin-left: 4%;
	margin-bottom: 2%;
	outline: none;
	padding: 1px 9px 1px 8px;
	background-color: #ffffff;
	border-radius: 3px !important;
	border: 1px solid #c2cad8;
  }

  

  .editentrytime {
	height: 38px;
	width: 76%;
	width: -1%;
	margin-right: 1%;
	margin-left: 1%;
	margin-bottom: 2%;
	outline: none;
	padding: 1px 9px 1px 8px;
	background-color: #ffffff;
	border-radius: 3px !important;
	border: 1px solid #c2cad8;
  }
  .editdateField {
	height: 34px;
	width: 70%;
	width: -1%;
	margin-right: 5%;
	margin-left: 4%;
	margin-bottom: 2%;
	outline: none;
	padding: 1px 9px 1px 8px;
	background-color: #ffffff;
	border-radius: 3px !important;
	border: 1px solid #c2cad8;
  }
.launchurlfield {
	height: 21px;
	outline: none;
	cursor: pointer;
	text-align: center;
	width: 90%;
	margin-top: 2%;
	background-color: #ffffff;
	border-radius: 6px !important;
	border: 1px solid #93c47d;
	color: #93c47d;
	font-weight: bold;
	padding: 3px 12px;
}
.launchurlfield:hover {
	background-color: #ccc;
}
.PackageinputField {
	height: 33px;
	width: 43%;
	margin-left: 56%;
	outline: none;
	padding: 0px 12px;
	background-color: #f4f4f4bd;
	border-radius: 3px !important;
	border: 1px solid #c2cad8;
}
.companyListinputField {
	height: 33px;
	width: 100%;
	outline: none;
	padding: 0px 12px;
	background-color: #f4f4f4;
	border-radius: 3px !important;
	border: 1px solid #c2cad8;
}

.licenseeListinputField {
	height: 33px;
	width: 26%;
	outline: none;
	padding: 0px 12px;
	background-color: #f4f4f4;
	border-radius: 3px !important;
	border: 1px solid #c2cad8;
}
.StatusinputField {
	height: 33px;
	width: 30%;
	outline: none;
	padding: 0px 12px;
	background-color: #f4f4f4;
	border-radius: 3px !important;
	border: 1px solid #c2cad8;
}
.editStatusinputField {
	height: 33px;
	width: 30%;
	outline: none;
	padding: 0px 12px;
	background-color: #f4f4f4;
	border-radius: 3px !important;
	border: 1px solid #c2cad8;
}
.editinputField {
	height: 33px;
	outline: none;
	font-size: 13px;
	width: 100%;
	padding: 0px 12px;
	background-color: #ffffff;
	border-radius: 3px !important;
	border: 1px solid #c2cad8;
}

.editinputFielderr {
	height: 33px;
	outline: none;
	font-size: 13px;
	width: 100%;
	padding: 0px 12px;
	background-color: #edd;
    border: 1px solid #c00;
	border-radius: 3px !important;
}
.MuiTableCell-root {
	border: 1px solid #e7ecf1 !important;
}
.editselectField {
	height: 33px;
	outline: none;
	width: 107%;
	padding: 0px 12px;
	background-color: #ffffff;
	border-radius: 3px !important;
	border: 1px solid #c2cad8;
}
.userPage {
	background-color: white;
    padding: 30px;
}
.clearfix {
	margin-bottom: 15px;
	margin-top: 8px;
	width: 100%;
	display: flex;
	font-size: 14px;
	flex-direction: row;
}
.subtabButton {
	color: #333;
	background-color: #e6e6e6;
	border: 1px solid #adadad;
	pointer-events: none;
	margin-bottom: 2px;
	flex: 1 1;
	border-radius: 6px !important;
	font-weight: bold;
	padding: 3px 12px;
}
.flexSpace {
	width: 20px;
}
.flexSpace8 {
	width: 80px;
}
.editPackageinputField {
	height: 33px;
	width: 43%;
	margin-left: 56%;
	outline: none;

	padding: 0px 12px;
	background-color: #ffffff;
	border-radius: 3px !important;
	border: 1px solid #c2cad8;
}
.checkboxSelect {
	margin-left: 10px;
	padding: 4px;
	background-color: rgb(216 216 216);
	border-radius: 3px !important;
	border-bottom: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
	/* border: 1px solid #c2cad8; */
}
.editcompanyListField {
	height: 33px;
	width: 100%;
	outline: none;
	padding: 0px 12px;
	background-color: #ffffff;
	border-radius: 3px !important;
	border: 1px solid #c2cad8;
}

.editcompanyListFielderr {
	height: 33px;
	width: 100%;
	outline: none;
	padding: 0px 12px;
	background-color: #edd;
	border-radius: 3px !important;
	border: 1px solid #c00;
}
.editlicenseeListField {
	height: 33px;
	width: 26%;
	outline: none;

	padding: 0px 12px;
	background-color: #ffffff;
	border-radius: 3px !important;
	border: 1px solid #c2cad8;
}
.dialoginput {
	height: 30px;
	width: 98%;
	font-size: 14px;
	color: #555;
	outline: none;
	padding-left: 7px;
    padding-right: 7px;
	margin-bottom: 10px;
	background-color: #ffffff;
	border-radius: 3px !important;
	border: 1px solid rgba(0, 0, 0, 0.2);
}

.errdialoginput {
	height: 30px;
	width: 98%;
	color: #555;
	outline: none;
	padding-left: 11px;
	margin-bottom: 10px;
  background-color: #edd;
	border-radius: 3px !important;
	border: 1px solid #c00;
}

.MnoDialoginput {
	height: 34px;
	width: 98%;
	color: #555;
	outline: none;
	margin-bottom: 10px;
	background-color: #ffffff;
	border-radius: 5px !important;
	border: 1px solid rgba(0, 0, 0, 0.2);
}
.MnoDialoginputError {
	height: 34px;
	width: 98%;
	color: #c00;
	outline: none;
	margin-bottom: 10px;
	background-color: #edd;
	border: 1px solid #c00;
	border-radius: 5px !important;

}
.formdialoginput {
	height: 30px;
	width: 98%;
	color: #555;
	outline: none;
	padding-left: 11px;
	margin-bottom: 10px;
	background-color: #ffffff;
	border-radius: 3px !important;
	border: 1px solid rgba(0, 0, 0, 0.2);
}
.errinput {
	height: 30px;
	width: 100%;
	color: #555;
	outline: none;
	padding-left: 11px;
	margin-bottom: 10px;
	background-color: #edd;
	border-radius: 3px !important;
	border: 1px solid #c00;
}
.dialogSelectinput {
	height: 36px;
	width: 101%;
	color: #555;
	padding-left: 1px;
	outline: none;
	margin-bottom: 10px;
	background-color: #ffffff;
	border-radius: 3px !important;
	border: 1px solid rgba(0, 0, 0, 0.2);
}
.errdialogSelectinput{
  height: 36px;
	width: 101%;
	color: #555;
	padding-left: 1px;
	outline: none;
	margin-bottom: 10px;

  background-color: #edd;
	border-radius: 3px !important;
	border: 1px solid #c00;
}
.companyListdisableField {
	height: 33px;
	width: 98%;
	outline: none;
  padding-left: 4px;
  margin-bottom: 10px;
	background-color: #f4f4f4;
	border-radius: 3px !important;
	border: 1px solid #c2cad8;
}
.cmpnydialoginput {
	height: 30px;
	width: 98%;
	color: #555;
	padding-left: 4px;
	outline: none;
	margin-bottom: 10px;
	background-color: #ffffff;
	border-radius: 3px !important;
	border: 1px solid rgba(0, 0, 0, 0.2);
}
.marginTop{
	margin-top: 0px !important;
}
.statusValid{
    border-radius: 5px;
    height: 24px;
}
.disabledialoginput {
	height: 30px;
	width: 97%;
	color: #555;
	padding-left: 11px;
	cursor: not-allowed;
	outline: none;
	margin-bottom: 10px;
	background-color: #efefef;
	border-radius: 3px !important;
	border: 1px solid rgba(0, 0, 0, 0.2);
}
.selectinputField {
	height: 33px;
	width: 100%;
	padding-left: 11px;
	color: #555;
	outline: none;
	margin-bottom: 10px;
	background-color: #ffffff;
	border-radius: 3px !important;
	border: 1px solid rgba(0, 0, 0, 0.2);
}
.errselectinputField {
	height: 33px;
	width: 100%;
	padding-left: 11px;
	color: #555;
	outline: none;
	margin-bottom: 10px;
	background-color: #edd;
	border-radius: 3px !important;
	border: 1px solid #c00;
}
.errcmpnydialoginput {
	height: 30px;
	width: 98%;
	color: #555;
	padding-left: 11px;
	outline: none;
	margin-bottom: 4px;
	background-color: #edd;
	border-radius: 3px !important;
	border: 1px solid #c00;
}
.redBtn{
	color:#cb5050 !important;
	border: 2px solid #cb5050 !important;
}
.ercmpnydialoginput {
	height: 30px;
	width: 94%;
	color: #555;
	padding-left: 11px;
	outline: none;
	margin-bottom: 4px;
	background-color: #edd;
	border-radius: 3px !important;
	border: 1px solid #c00;
}
.ereditInputField {
	background-color: #edd;
	border-radius: 3px !important;
	border: 1px solid #c00;
	height: 33px;
	padding-left: 11px;
	outline: none;
	width: 100%;
	padding: 0px 12px;
}
.userinput {
	height: 30px;
	width: 100%;
	color: #555;
	padding-left: 11px;
	outline: none;
	margin-bottom: 10px;
	background-color: #f4f4f4;
	border-radius: 3px !important;
	border: 1px solid rgba(0, 0, 0, 0.2);
}
.erruserinput {
	height: 30px;
	width: 100%;
	padding-left: 11px;
	color: #555;
	outline: none;
	margin-bottom: 10px;
  background-color: #edd;
	border-radius: 3px !important;
	border: 1px solid #c00;
}
.edituserinput {
	height: 30px;
	width: 100%;
	padding-left: 11px;
	color: #555;
	outline: none;
	margin-bottom: 10px;
	background-color: #ffffff;
	border-radius: 3px !important;
	border: 1px solid rgba(0, 0, 0, 0.2);
}
.Dmargin{
	margin-left: 16px;;
}
.domainEditUser {
	height: 34px;
	width: 100%;
	padding-left: 11px;
	color: #555;
	outline: none;
	margin-bottom: 10px;
	background-color: #ffffff;
	border-radius: 3px !important;
	border: 1px solid rgba(0, 0, 0, 0.2);
}

.dialoginputValue {
	height: 30px;
	cursor: not-allowed;
	width: 98%;
	color: #555;
	font-size: 14px;
	padding-left: 11px;
	outline: none;
	margin-bottom: 10px;
	background-color: #f4f4f4;
	border-radius: 3px !important;
	border: 1px solid rgba(0, 0, 0, 0.2);
}
.dialogtextArea {
	height: 85px;
	cursor: not-allowed;
	width: 100%;
	color: #555;
	outline: none;
	margin-bottom: 10px;
	background-color: #f4f4f4;
	border-radius: 3px !important;
	border: 1px solid rgba(0, 0, 0, 0.2);
}
.dialogtitle {
	font-weight: normal !important;
	color: #333;
	border: 1px solid rgba(0, 0, 0, 0.2);
}
.dialoginputtext {
	font-size: 14px;
	margin-bottom: 11px;
	font-weight: 300;
	margin-top: 11px;
	word-break: break-word;
}
.passwodinputtext {
	font-size: 16px;
	margin-top: 10px;
	margin-bottom: 10px;
	font-weight: 300;
	margin-top: 20px;
}
.ddflex {
	flex: 1 1;
	margin: 0px 25px;
	text-align: left;
}
.entypointflex {
	flex: 1 1;
	text-align: left;
}
.cmflex {
	flex: 1;
	margin: 0px 25px;
	text-align: left;
}
.cmpnylogoflex {
	flex: 1 1;
	margin: 0px 25px;
	text-align: left;
}
.companyListflex {
	flex: 1;
	margin: 4px 25px;
}
.companyEditListflex {
	flex: 1 1 10%;
	margin: 0px 25px;
	text-align: left;
}
.customerListflex {
	flex: 3;
	margin: 0px 25px;
	text-align: left;
}
.companyLabel {
	font-weight: normal !important;
	font-style: normal;
	color: #221f1fe0;
	font-size: 13px;
	margin-bottom: 5px;
	text-align: left;
}

.company_Label {
  font-weight: 300 !important;
  font-style: normal;
  color: #221f1fe0;
  font-size: 14px;
  margin-bottom: 5px;
  text-align: left;
}

.packageLabel {
	font-weight: normal !important;
	font-style: normal;
	color: #221f1fe0;
	font-size: 15px;
	text-align: left;
	margin-bottom: 5px;
	margin-right: 20%;
}
.inviteButton {
	border-radius: 6px !important;
	border: 1px solid #93c47d;
	font-size: 14px;
	height: 31px;
	color: #93c47d;
	font-weight: bold !important;
	padding: 2px 14px !important;
	cursor: pointer;
	margin-right: 9px;
	margin-bottom: 10px;
	background-color: #fff;
}

.inviteApprove {
	border-radius: 6px !important;
	font-size: 14px;
	height: 31px;
	border: 2px solid #1e90ff;
    color: #1e90ff;
	font-weight: bold !important;
	padding: 2px 14px !important;
	cursor: pointer;
	margin-right: 9px;
	margin-bottom: 10px;
	background-color: #fff;
}

.inviteButtonDirect {
	border-radius: 6px !important;
	font-size: 14px;
	height: 31px;
	border: 2px solid #d2f3c3;
    color: #afe199;
	font-weight: bold !important;
	padding: 2px 14px !important;
	cursor: pointer;
	margin-right: 9px;
	margin-bottom: 10px;
	background-color: #fff;
}

.inviteButtonconnect {
	border-radius: 6px !important;
    border: 2px solid #1e90ff;
    color: #1e90ff;
	font-size: 14px;
	height: 31px;
	font-weight: bold !important;
	padding: 2px 14px !important;
	cursor: pointer;
	margin-right: 9px;
	margin-bottom: 10px;
	margin-top: 27px;
	background-color: #fff;
}
.inviteButtonconnect:hover{
	color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
}
.approveButtonDirect {
	border-radius: 6px !important;
    border: 2px solid #1e90ff;
    color: #1e90ff;
	font-size: 14px;
	height: 31px;
  /* width:100%; */
	font-weight: bold !important;
	padding: 2px 14px !important;
	cursor: pointer;
	margin-right: 9px;
	margin-bottom: 10px;

	background-color: #fff;
}

.styleColor{
  color: #852b32 !important;
}
.approveButtonDirect:hover{
	color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
}
.ButtonDirect {
  border-radius: 6px !important;
  border: 2px solid #ff0000;
  color: #ff0000;
	font-size: 14px;
	height: 31px;
  width:100%;
	font-weight: bold !important;
	padding: 2px 14px !important;
	cursor: pointer;
	margin-right: 9px;
	margin-bottom: 10px;

	background-color: #fff;
}
.react-time-picker__wrapper {
	border: none!important;
}
.react-time-picker__button{
	display: none !important;
}
.labelBoxStyle{
	text-align: left;
    margin-bottom: 5%;
}
.ButtonDirect:hover{
	 color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
}
.border{
  border-bottom: none!important;
}
.warningIcon{
  font-size: 20px;
  color: #ffa500 !important;
}
.fileIcon img{
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 7px !important;
}
.logoConfirmtext{
   text-align: center;
    font-size: 14px;
    margin: 15px 0;
}
.warningText{
  font-size: 20px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  line-height: 26px;
}
.ContentHead{
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
}
.invitedisabledButton {
  background-color: #efefef;
	border-radius: 6px !important;
	border: 1px solid rgba(0, 0, 0, 0.2);
	font-size: 14px;
	height: 31px;
  color: #555;
	font-weight: bold !important;
	padding: 2px 14px !important;
	cursor: not-allowed;
	margin-right: 9px;
	margin-bottom: 10px;

}

.clearButton {
	background-color: #efefef;
	  border-radius: 6px !important;
	  border: 1px solid rgba(0, 0, 0, 0.2);
	  font-size: 14px;
	  height: 34px;
	  color: #555;
	  font-weight: bold !important;
	  padding: 2px 14px !important;
	  cursor: not-allowed;
	  margin-right: 9px;
	  margin-bottom: 10px;
  
  }
  .EditEvent {
	 background-color: white;
	  border-radius: 6px !important;
	  border: 3px solid #93C47D;
      color: #93C47D;
	  font-size: 14px;
	  height: 34px;
	  font-weight: bold !important;
	  padding: 2px 14px !important;
	  margin-right: 9px;
	  margin-bottom: 10px;
  
  }
  .clearblueButton {
	background-color: white;
	  border-radius: 6px !important;
	  border: 2px solid #1e90ff;
      color: #1e90ff;
	  font-size: 14px;
	  height: 34px;
	  font-weight: bold !important;
	  padding: 2px 14px !important;
	  cursor: pointer;
	  margin-right: 9px;
	  margin-bottom: 10px;
  
  }
.inviteButton:hover,.clearblueButton:hover, .inviteApprove:hover  {
	background-color: #5555;
	color: black !important;
	border: 1px solid #afa0bb !important;
}
.NavTopText {
	text-align: center;
	font-family: "Open Sans", sans-serif;
	font-weight: 300;
	font-size: 18px;
	color: #333333;
	padding: 15px;
}

.changeOwnButton {
	border-radius: 6px !important;

	font-size: 14px;
	height: 31px;
	border: 2px solid #1e90ff !important;
	color: #1e90ff;
	font-weight: bold !important;
	padding: 2px 14px !important;
	cursor: pointer;
	margin-right: 9px;
	margin-bottom: 10px;
	background-color: #fff;
}
.changeOwnButton:hover {
	background-color: #5555;
	color: black !important;
	border: 1px solid #afa0bb !important;
}
.BtnsALign {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 2%;
}
.headformText {
	font-weight: 700;
	text-align: center;
	margin: 19px;
	/* margin-top: 11px; */
	font-size: 14px;
	color: inherit;
	font-family: "Open Sans", sans-serif !important;
}
.continueBtn {
	border-radius: 6px !important;
	border: 2px solid #ff0000;
	color: #ff0000;
	font-size: 14px;
	background-color: white;
	margin-right: 8px;
	padding: 2px 14px !important;
	outline: none !important;
	box-shadow: none !important;
	cursor: pointer;
	height: 31px;
	margin-bottom: 10px;
}
.continueBtn:hover,.continueBtn:active{
	color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
}

.continueBtns {
	border-radius: 6px !important;
	border: 3px solid #93C47D;
	color: #93C47D;
	width:40%;
	font-size: 14px;
	background-color: white;
	margin-right: 8px;
	padding: 2px 14px !important;
	outline: none !important;
	box-shadow: none !important;
	cursor: pointer;
	height: 31px;
	margin-bottom: 10px;
}
.continueBtns:hover,.continueBtns:active{
	color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
}
.userEditButton {
	border-radius: 6px !important;
	border: 2px solid #93c47d !important;
	color: #93c47d !important;
	font-weight: bold !important;
	padding: 7px 14px !important;
	cursor: pointer;
	/* margin-top: 5%; */
	margin-right: 9px;
	/* margin-bottom: 10px; */
}
.invitesecButton {
	border-radius: 6px !important;
	border: 2px solid #93c47d !important;
	color: #93c47d !important;
	font-weight: bold !important;
	padding: 7px 14px !important;
	cursor: pointer;
	margin-top: 5%;
	margin-right: 9px;
	margin-bottom: 10px;
}
.editsecButton {
	border-radius: 6px !important;
	border: 2px solid #93c47d !important;
	color: #93c47d !important;
	font-weight: bold !important;
	padding: 7px 14px !important;
	cursor: pointer;
	margin-right: 9px;
	margin-bottom: 10px;
}
.impesonatebtn {
	color: #fff;
	background-color: #337ab7;
	border-color: #2e6da4;
	display: inline-block;
	margin-bottom: 0;
	font-weight: 400;
	text-align: center;
	cursor: pointer;
	white-space: nowrap;
	border: 1px solid transparent;

	padding: 6px 12px;
	font-size: 14px;

	border-radius: 4px;
}
.buttonRow {
	display: flex;
	flex-direction: row;
	margin-left: 3%;

	margin-bottom: 4%;
	justify-content: space-between;
}
.alignTop{
	margin-top: 27px !important;
}
.buttonRowFlex {
	display: flex;
	flex-direction: row;
	margin: 12px 0px 12px 0px;
	justify-content: space-between;
}
.ivitediv {
	text-align: right;
}
.ivitecustdiv {
	text-align: right;
	flex: 2;
}
.invitedialogue {
	width: "600px" !important;
}
.MuiDialogActions-root {
	flex: 0 0 auto !important;
	display: block !important;
	padding: 8px !important;
	align-items: center !important;
	justify-content: flex-end !important;
}
.flex3 {
	flex: 3;
}
.flex6 {
	flex: 6;
}
.flex1 {
	flex: 1;
}
.border {
	border: "2px solid red" !important;
}
.customerddflex {
	margin: 0px 25px;
	flex: 3;
}
.subhead {
	font-size: 14px !important;
	font-weight: 500 !important;
	margin-bottom: 10px !important;
}
.MuiDialog-paperScrollPaper {
	display: flex;
	max-height: calc(100% - 64px);
	flex-direction: column;
	/* border: 1px solid red; */
}
@media only screen and (max-width: 949px) {
	.InputflexRow {
		display: flex;
		flex-direction: column;
		margin-bottom: 15px;
	}
	.userPage {
		margin-top: 89px;
	}
	.accountView {
		background-color: #ffffff;
	}
	.accountContent {
		background-color: #ffffff;
	}
	.tabChange {
		margin-top: 10%;
	}

	.companyForm {
		margin-right: 0%;
	}
	.ddflex {
		margin: 0px 0px;
	}
}
